import {API_URL} from '../constants'
import { Amplify, Auth } from "aws-amplify";

const checkRole = (grups) => {
    debugger;
    if (grups && grups.includes('moderators')) {
        return 'moderator'
    } else if (grups && grups.includes('designer')){
        return 'designer'
    }else {
        return 'user'
    }
}

export const getAuthInfo = async ()=> {
   const res = await Auth.currentAuthenticatedUser().then((user)=>(
       {name:user.username,
           token:user.signInUserSession.accessToken.jwtToken,
           isLoggedIn:true,
           role: checkRole(user.signInUserSession.idToken.payload["cognito:groups"])
       }));

    return res;
}

export const logOut = async () => {
    try {
        await Auth.signOut();
        return { name:'',token:'', isLoggedIn:false, role: ''};
    } catch (error) {
        return {
            success: false,
            message: "LOGOUT FAIL",
        };
    }
};

