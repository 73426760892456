export default {
    setOrders: (state, param) => {
        const {payload} = param;
        return state.data = payload;
    },
    setOrder: (state, param) => {
        const {payload} = param;
        state.data[payload.id] = payload
    },
    clearFilters: (state) => {
        state.queryParams = {perPage: 25, page: 1, sort: 'createdAt', sortDirection:'DESC', orderStatusId: null, createdAt_max: null}
    }
}
