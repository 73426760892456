import {getOrders, getOrder} from "../../thunk";

const getID = (id) => {
    return `a${id}a`
}

export default {
    [getOrders.pending as any]: (state) => {
        state.status = 'loading';
        state.error = null;
    },
    [getOrders.fulfilled as any]: (state, action) => {
        state.status = 'resolved';
        state.data = action.payload.result.reduce((acc, prev) => ({...acc, [getID(prev.orderId)]: prev}), []);
        state.queryParams = {...action.meta.arg ,page: +action.payload.page, perPage: +action.payload.perPage, total: +action.payload.totalCount}
    },
    [getOrders.rejected as any]: (state, action) => {
        state.status = 'rejected';
        state.error = action.payload;
    },
    [getOrder.pending as any]: (state) => {
        state.status = 'loading';
        state.error = null;
    },
    [getOrder.fulfilled as any]: (state, action) => {
        state.status = 'resolved';
        console.log(action.payload, 'reduser')
        state.data[getID(action.payload.orderId)] = {...state.data[getID(action.payload.orderId)], ...action.payload}
    },
    [getOrder.rejected as any]: (state, action) => {
        state.status = 'rejected';
        state.error = action.payload;
    }
}
