import React, { useState } from 'react';
import {PRINTFULL_ID, FREEPRINTS_ID} from '../../constants'
/** Icons */
import WebStoriesIcon from '@mui/icons-material/WebStories';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import PhotoIcon from '@mui/icons-material/Photo';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ListAltIcon from '@mui/icons-material/ListAlt';
import StoreIcon from '@mui/icons-material/Store';
import InventoryIcon from '@mui/icons-material/Inventory';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import { useStyles } from './styles';
/** Constants */
import { Link, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import {useSelector} from "react-redux";
import {IStore} from "../../store/types";
import {Accordion, AccordionDetails, AccordionSummary} from "@mui/material";

/** Interfaces */
interface INavItem {
  title: string;
  icon: (size?: boolean) => JSX.Element;
  link: string;
  subNavigation?: { titleName: string; link: string }[];
}
const LeftNavigationBar = () => {

  const navigationItemsStories: INavItem[] = [
    { title: 'Authors', link: 'authors', icon: (size?: boolean) => <PeopleAltIcon sx={{ color: '#047d95' }} fontSize={size ? 'large': 'medium'}/>},
    { title: 'Stories', link: 'stories', icon: (size?: boolean) => <WebStoriesIcon sx={{ color: '#047d95' }} fontSize={size ? 'large': 'medium'} /> },
  ]

  const navigationItemsAPI: INavItem[] = [
    { title: 'Printfull Orders', link: `${PRINTFULL_ID}/orders`, icon: (size?: boolean) => <ListAltIcon sx={{ color: '#047d95' }} fontSize={size ? 'large': 'medium'}/>},
    { title: 'Freeprints Orders', link: `${FREEPRINTS_ID}/orders`, icon: (size?: boolean) => <ListAltIcon sx={{ color: '#047d95' }} fontSize={size ? 'large': 'medium'}/>},
    { title: 'Test Orders', link: `19648/orders`, icon: (size?: boolean) => <ListAltIcon sx={{ color: '#047d95' }} fontSize={size ? 'large': 'medium'}/>},
    { title: 'Products', link: 'products', icon: (size?: boolean) => <InventoryIcon sx={{ color: '#047d95' }} fontSize={size ? 'large': 'medium'}/>},
    { title: 'Companies', link: 'companies', icon: (size?: boolean) => <StoreIcon sx={{ color: '#047d95' }} fontSize={size ? 'large': 'medium'}/>},
    { title: 'Analytics', link: 'analytics', icon: (size?: boolean) => <ShowChartIcon sx={{ color: '#047d95' }} fontSize={size ? 'large': 'medium'}/>}
  ]

  const navigationItemsModerators: INavItem[] = [
    { title: 'Authors', link: 'authors', icon: (size?: boolean) => <PeopleAltIcon sx={{ color: '#047d95' }} fontSize={size ? 'large': 'medium'}/>},
    { title: 'Stories', link: 'stories', icon: (size?: boolean) => <WebStoriesIcon sx={{ color: '#047d95' }} fontSize={size ? 'large': 'medium'} /> },
    { title: 'Printfull', link: 'orders', icon: (size?: boolean) => <PeopleAltIcon sx={{ color: '#047d95' }} fontSize={size ? 'large': 'medium'}/>},
    { title: 'Image quality', link: 'image-quality', icon: (size?: boolean) => <PhotoIcon sx={{ color: '#047d95' }} fontSize={size ? 'large': 'medium'} /> },
  ];
  const navigationItemsDesigners: INavItem[] = [
    { title: 'Image quality', link: 'image-quality', icon: (size?: boolean) => <PhotoIcon sx={{ color: '#047d95' }} fontSize={size ? 'large': 'medium'} /> },
  ];
  const role = useSelector<IStore, any>((state) => {
    return state.auth.data.role});
  const classes = useStyles();
  const locationLink = useLocation().pathname.substring(1).toLowerCase() + useLocation().search;
  return (
      <div className={classes.root}>
        {role === 'admin' &&
            <>
              <Accordion className={classes.customAcordion} defaultExpanded={locationLink.includes(FREEPRINTS_ID || '') || locationLink.includes(PRINTFULL_ID ||'') || locationLink.includes('19648') ? true : false}>
                <AccordionSummary id="panel-header" aria-controls="panel-content"  expandIcon={<ExpandMoreIcon />}>
                  <b>API Integration</b>
                </AccordionSummary>
                <AccordionDetails>
                  <div className={classes.itemsWrapper}>
                    {navigationItemsAPI.map((item: INavItem, index: number) => (

                        <Link
                            to={`/${item.link}`}
                            key={index}
                            className={classNames(classes.itemContainer, {
                              [classes.activeRoute]:
                                  locationLink.includes(item.link),
                            })}
                        >
                          <div className={classes.linkConetnt}>
                            <div className={classes.itemIcon}>{item.icon((locationLink.includes(item.link)) && true)}</div>
                            <div className={classes.itemTitle}>{item.title}</div>
                          </div>
                        </Link>
                    ))}
                  </div>
                </AccordionDetails>
              </Accordion>
              <Accordion className={classes.customAcordion} defaultExpanded={locationLink.includes('stor') || locationLink.includes('author') ? true : false}>
                <AccordionSummary id="panel-header" aria-controls="panel-content" expandIcon={<ExpandMoreIcon />}>
                  <b>Customer Stories</b>
                </AccordionSummary>
                <AccordionDetails>
                  <div className={classes.itemsWrapper}>
                    {navigationItemsStories.map((item: INavItem, index: number) => (

                        <Link
                            to={`/${item.link}`}
                            key={index}
                            className={classNames(classes.itemContainer, {
                              [classes.activeRoute]:
                                  item.link.includes(locationLink),
                            })}
                        >
                          <div className={classes.linkConetnt}>
                            <div className={classes.itemIcon}>{item.icon((locationLink.includes(item.link)) && true)}</div>
                            <div className={classes.itemTitle}>{item.title}</div>
                          </div>
                        </Link>
                    ))}
                  </div>
                </AccordionDetails>
              </Accordion>
            </>
        }
        {role === 'moderator' &&
            <>
              <Accordion className={classes.customAcordion} defaultExpanded={locationLink.includes(FREEPRINTS_ID || '') || locationLink.includes(PRINTFULL_ID || '') || locationLink.includes('19648')? true : false}>
                <AccordionSummary id="panel-header" aria-controls="panel-content"  expandIcon={<ExpandMoreIcon />}>
                  <b>API Integration</b>
                </AccordionSummary>
                <AccordionDetails>
                  <div className={classes.itemsWrapper}>
                    {navigationItemsAPI.map((item: INavItem, index: number) => (

                        <Link
                            to={`/${item.link}`}
                            key={index}
                            className={classNames(classes.itemContainer, {
                              [classes.activeRoute]:
                                  locationLink.includes(item.link),
                            })}
                        >
                          <div className={classes.linkConetnt}>
                            <div className={classes.itemIcon}>{item.icon((locationLink.includes(item.link)) && true)}</div>
                            <div className={classes.itemTitle}>{item.title}</div>
                          </div>
                        </Link>
                    ))}
                  </div>
                </AccordionDetails>
              </Accordion>
              <Accordion className={classes.customAcordion} defaultExpanded={locationLink.includes('stor') || locationLink.includes('author') ? true : false}>
                <AccordionSummary id="panel-header" aria-controls="panel-content" expandIcon={<ExpandMoreIcon />}>
                  <b>Customer Stories</b>
                </AccordionSummary>
                <AccordionDetails>
                  <div className={classes.itemsWrapper}>
                    {navigationItemsStories.map((item: INavItem, index: number) => (

                        <Link
                            to={`/${item.link}`}
                            key={index}
                            className={classNames(classes.itemContainer, {
                              [classes.activeRoute]:
                                  item.link.includes(locationLink),
                            })}
                        >
                          <div className={classes.linkConetnt}>
                            <div className={classes.itemIcon}>{item.icon((locationLink.includes(item.link)) && true)}</div>
                            <div className={classes.itemTitle}>{item.title}</div>
                          </div>
                        </Link>
                    ))}
                  </div>
                </AccordionDetails>
              </Accordion>
            </>
        }
        {role === 'designer' &&
            navigationItemsDesigners.map((item: INavItem, index: number) => (
                <Link
                    to={`/${item.link}`}
                    key={index}
                    className={classNames(classes.itemContainer, {
                      [classes.activeRoute]:
                          locationLink.includes(item.link),
                    })}
                >
                  <div className={classes.linkConetnt}>
                    <div className={classes.itemIcon}>{item.icon((locationLink.includes(item.link)) && true)}</div>
                    <div className={classes.itemTitle}>{item.title}</div>
                  </div>
                </Link>
            ))}
      </div>
  );
};

export default LeftNavigationBar;
