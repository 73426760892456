import * as React from 'react';
import {useStyles} from "./styles";
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {Button, Checkbox, CircularProgress, FormControl, Input, InputLabel, ListItemText, MenuItem, Select, TextField} from "@mui/material";
import  Dayjs  from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {Orders} from "../../store/slices";
import debounce from 'lodash/debounce';
import * as yup from "yup";
import {useParams} from "react-router-dom";

const OrderStatus = [
   {status: 'Created', color:'#00ccff54', value: 1},
   {status: 'Production', color:'#ffa500ab', value: 2},
   {status: 'Shipped', color:'#4bd30899', value: 3},
   {status: 'Partial Shipped', color:'#8ffa98', value: 7},
   {status: 'Canceled', color:'#80808073', value: 5},
   {status: 'Error', color:'#ff000073', value: 6},
   {status: 'Hold', color:'rgba(224,0,255,0.45)', value: 8},
]

const StoryPublished = [
    // {status: 'All', value: null },
    {label: 'Yes', value: '1'},
    {label: 'No', value: '0'},
]

export default function OrdersFilters(props: any) {
    const { queryParams, loadOrders, loading } = props;
    const [clear, setClear] = useState(false)
    const dispatch = useDispatch();
    const { id } = useParams();
    const classes = useStyles();
    const [searchError, setSearchError] = useState<any>({})
    console.log(queryParams, 'LOADED PARAMS')
    const handleFilterChange = (e) => {
        console.log(e)
        debugger;
        const { name, value } = e.target;
        const newQueryParams = {
            ...queryParams,
            page: 1,
            [name]: value,
        }
        if(name === 'maxDate' && !newQueryParams.minDate){
            newQueryParams.minDate = '2024-01-01'
        }
        if(name === 'minDate' && !newQueryParams.maxDate){
            newQueryParams.maxDate = Dayjs(new Date()).format("YYYY-MM-DD")
        }

        console.log('PARAMS', newQueryParams)
        loadOrders(newQueryParams);
    }
    const searchHandle = (e) => {
        if(e.target.value.length < 3){
            setSearchError({message: 'min 3 characters'})
            handleFilterChange(e);
        } else {
            setSearchError({})
            handleFilterChange(e);
        }
        if(e.target.value.length === 0){
            setSearchError({})
        }
    }
    const debouncedOnChange = debounce(searchHandle, 500);
    useEffect(()=>{
        setClear(false)
    },[loading])
    // @ts-ignore
    return (
        <div className={classes.filtersWrap}>
            {/*<FormControl sx={{ m: 1,  width: '15ch', marginLeft: 0 }}  size="small">*/}
            {/*    <InputLabel id="demo-simple-select-helper-label">Published</InputLabel>*/}
            {/*    <Select*/}
            {/*        id="demo-simple-select-helper"*/}
            {/*        defaultValue={queryParams?.isPublished || null}*/}
            {/*        label="Published"*/}
            {/*        variant="outlined"*/}
            {/*        name="isPublished"*/}
            {/*        size="small"*/}
            {/*        onChange={(event) =>handleFilterChange(event)}*/}
            {/*    >*/}
            {/*        {StoryPublished.map((story)=>*/}
            {/*            <MenuItem key={`${story.value}${story.label}`} value={story.value}>{story.label}</MenuItem>*/}
            {/*        )}*/}
            {/*    </Select>*/}
            {/*</FormControl>*/}
            <FormControl sx={{ m: 1,  width: '15ch' }}>

                {!clear && <TextField label="Search by Id" variant="outlined"
                           name='search'
                           InputProps={{
                                    type: 'number',
                            }}
                           defaultValue={queryParams?.search ? queryParams?.search : ''}
                           disabled={loading}
                           onChange={debouncedOnChange}
                           error={Boolean(searchError?.message)}
                           helperText={searchError?.message}
                />}
        </FormControl>
            <FormControl sx={{ m: 1,  width: '15ch' }} >
                <InputLabel id="demo-simple-select-helper-label">Status</InputLabel>
                <Select
                    id="demo-simple-select-helper"
                    // defaultValue={queryParams?.orderStatusId ? queryParams?.orderStatusId : []}
                    value={queryParams?.orderStatusId ? queryParams?.orderStatusId : []}
                    label="Status"
                    variant="outlined"
                    name="orderStatusId"
                    onChange={(event) =>handleFilterChange(event)}
                    multiple
                    disabled={loading}
                >
                    {OrderStatus.map((order)=>
                        <MenuItem key={order.value} value={order.value}>{order.status}
                            {/*<Checkbox checked={queryParams?.orderStatusId && queryParams?.orderStatusId.indexOf(order.value) > -1} />*/}
                            {/*<ListItemText primary={order.status} />*/}
                        </MenuItem>
                    )}
                </Select>
            </FormControl>
            <FormControl sx={{ m: 1,  width: '17ch', }} >
                <LocalizationProvider dateAdapter={AdapterDayjs} >
                    <DatePicker
                        label="Min Date"
                        format="YYYY-MM-DD"
                        value={ queryParams?.minDate && queryParams?.minDate !== '2024-01-01' ? Dayjs(queryParams?.minDate) : null }
                        maxDate={queryParams?.maxDate && Dayjs(queryParams?.maxDate) || Dayjs(new Date())}
                        // @ts-ignore
                        valueType="date"
                        disabled={loading}
                        onChange={(newValue) =>handleFilterChange({target:{name: 'minDate', value: newValue.format("YYYY-MM-DD")}})}
                        renderInput={(params) => <TextField {...params} />}
                    />
                </LocalizationProvider>
            </FormControl>
            <FormControl sx={{ m: 1,  width: '17ch', }} >
            <LocalizationProvider dateAdapter={AdapterDayjs} >
                <DatePicker
                    label="Max Date"
                    format="YYYY-MM-DD"
                    value={queryParams?.maxDate && Dayjs(queryParams?.maxDate) || Dayjs(new Date())}
                    maxDate={Dayjs(new Date())}
                    // @ts-ignore
                    valueType="date"
                    disabled={loading}
                    onChange={(newValue) =>handleFilterChange({target:{name: 'maxDate', value: newValue.format("YYYY-MM-DD")}})}
                    renderInput={(params) => <TextField {...params} />}
                />
            </LocalizationProvider>
            </FormControl>
            <FormControl sx={{ m: 1,  width: '10ch' }} >
                <div className={classes.editButton}
                     onClick={() => {
                         dispatch(Orders.clearFilters());
                         setClear(true);
                         loadOrders({
                             perPage: 25,
                             page: 1,
                             sort: 'createdAt',
                             sortDirection:'DESC',
                             orderStatusId: null,
                             createdAt_max: null,
                             search: null,
                             companyId: id});
                     }}
                >Clear</div>
            </FormControl>
        </div>
    );
}