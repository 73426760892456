import React, {useEffect} from 'react';
import { useStyles } from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';
import PageContainer from '../../../components/PageContainer';
import {IStory, IStore, IOneStory} from "../../../store/types";
import {Orders} from '../../../store/slices'

import GeneralForm from "../../../components/GeneralForm";
import {Paper} from "@mui/material";
import StoryForm from "../../../components/StoryForm";
import GiftCadsForm from "../../../components/GiftCardsForm";
import Skeleton from "@mui/material/Skeleton";

/** Components */
export type TOrderProps = {
    id: number
}
const getID = (id) => {
    return `a${id}a`
}


const OneOrder = () => {
  const { id, companyId } = useParams();
  const dispatch = useDispatch();
  const order = useSelector<IStore, any>((state) => {
      return {data: { ...state.orders.data[getID(id) || '']}, status: state.orders.status }});
console.log(order);
  useEffect(() => {
        dispatch<any>(Orders.getOrder({id: id || '', companyId: companyId || ''}));
  }, [])

  return (
      <PageContainer>
          <h2 style={{marginTop: 0, color:'#047d95', marginBottom: '40px', fontWeight: 600}}>Order Id : {id}</h2>
          {order.status === 'resolved' &&
          <Paper sx={{ width: '100%', overflow: 'hidden', padding: '20px 10px', maxHeight: '80vh', overflowY: 'scroll' }}>
              {Object.keys(order.data).length !== 0 && <>
                  <GeneralForm data={order} />
              </>}
          </Paper>}
          {order.status === 'loading' &&
              <Skeleton animation="wave" width={'100%'} height={600} variant="rounded" />}
      </PageContainer>
  );
};

export default OneOrder;
