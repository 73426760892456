import {API_URL} from '../constants'
import store from '../store';
import {setNotifications} from "../store/slices/notifications";

export const stringifyParameters = (parameters) => {
    const query = [];
    try {
        Object.keys(parameters)
            .filter((key) => parameters[key])
            .forEach((key) => {
                switch (key) {
                    case 'page':
                    case 'statusId':
                    case 'authorId':
                    case 'isPublished': {
                        // @ts-ignore
                        query.push(`${key}=${parameters[key]}`);
                        break;
                    }
                    case 'perPage': {
                        // @ts-ignore
                        query.push(`limit=${parameters[key]}`);
                        break;
                    }
                    default: {
                        break;
                    }
                }
            });
    } catch (error) {
        console.warn('Looks like something wrong with fetch products url parameters');
    }
    return `${query.join('&')}`;
};


export const getAllStories = async (queryParams) => {
    const token = store.getState().auth.data.token;
    const res = await fetch(`${API_URL}secure/stories?${stringifyParameters(queryParams)}`,{
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${encodeURIComponent(token)}`
        },
    });
    if (!res.ok) {
        throw new Error('Server Error!');
    }
    return await res.json();
};

export const getStoryById = async (id) => {
    const token = store.getState().auth.data.token;
    const res = await fetch(`${API_URL}secure/stories/${id}`,{
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${encodeURIComponent(token)}`
        },
    });
    if (!res.ok) {
        throw new Error('Server Error!');
    }
    return await res.json();
};
export const updateStoryById = async (id, data) => {
    const token = store.getState().auth.data.token;
    const res = await fetch(`${API_URL}secure/stories/${id}`,{
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${encodeURIComponent(token)}`
        },
        body: JSON.stringify({...data})
    });
    if (!res.ok) {
        throw new Error('Server Error!');
    }
    return await res.json();
};
export const createStory = async (data) => {
    const token = store.getState().auth.data.token;
    const res = await fetch(`${API_URL}secure/stories`,{
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${encodeURIComponent(token)}`
        },
        body: JSON.stringify({...data})
    });
    if (!res.ok) {
        throw new Error('Server Error!');
    }
    return await res.json();
};
export const getStoryGiftCards = async (data) => {
    const token = store.getState().auth.data.token;
    const res = await fetch(`${API_URL}secure/gift-cards?gfStoryId=${data.id}&${stringifyParameters(data.queryParams)}`,{
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${encodeURIComponent(token)}`
        },
    });
    if (!res.ok) {
        throw new Error('Server Error!');
    }
    return await res.json();
};

export const createGiftCard = async ({data,  onSuccess}) => {
    const token = store.getState().auth.data.token;
    const res = await fetch(`${API_URL}secure/gift-cards`,{
        method: "POST",
        // mode: "no-cors", // no-cors, *cors, same-origin
        headers: {
            "Content-Type": "application/json",
            // 'Content-Type': 'application/x-www-form-urlencoded',
            // 'Access-Control-Allow-Origin': '*',
            "Authorization": `Bearer ${encodeURIComponent(token)}`
        },
        body: JSON.stringify({...data})
    });
    if (!res.ok) {
        throw new Error('Server Error!');
    }
    const rest = await res.json();
    if (onSuccess) {
        onSuccess(rest);
    }
    return rest;
};

export const disableGiftCard = async ({id,  onSuccess}) => {
    const token = store.getState().auth.data.token;
    const res = await fetch(`${API_URL}secure/gift-cards/${id}/disable`,{
        method: "POST",
        // mode: "no-cors", // no-cors, *cors, same-origin
        headers: {
            "Content-Type": "application/json",
            // 'Content-Type': 'application/x-www-form-urlencoded',
            // 'Access-Control-Allow-Origin': '*',
            "Authorization": `Bearer ${encodeURIComponent(token)}`
        },
    });
    if (!res.ok) {
        throw new Error('Server Error!');
    }
    const rest = await res;
    if (onSuccess) {
        onSuccess(rest);
    }
    return rest;
};

export const getS3Url = async ({blob,  onSuccess}) => {
    const res = await fetch(`${API_URL}open/media/signed-url`,{
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({type: blob.type})
    });
    if (!res.ok) {
        throw new Error('Server Error!');
    }
    const data = await res.json();
    return S3Uploud(blob, data, onSuccess);
};

export const S3Uploud = async (blob, data, onSuccess) => {
    const res = await fetch(`${data.signed_url}`,{
        method: "PUT",
        headers: {
            "Content-Type": blob.type,
            "Content-Disposition": `attachment; filename="${data.file_name}"`
        },
        body: blob
    });
    if (!res.ok) {
        throw new Error('Server Error!');
    }
    if (onSuccess) {
        onSuccess(data);
    }
    return res;
};

export const getVideoUrl = async () => {
    const res = await fetch(`${API_URL}open/media/video`,{
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({title: 'story video',collectionId: 'de3b0674-d079-4a7d-a6e7-0ab1996d07e9'})
    });
    if (!res.ok) {
        throw new Error('Server Error!');
    }
    return await res.json();
};


