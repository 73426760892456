import * as React from 'react';
import {useStyles} from "./styles";
import {useDispatch, useSelector} from "react-redux";
import {useState} from "react";
import {Button, CircularProgress, FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import {Stories} from "../../store/slices";

const StoryStatus = [
    // {status: 'All', value: null },
    {status: 'Created', color:'#00ccff54', value:1},
    {status: 'Pending review', color:'#ffa500ab', value:2},
    {status: 'Approved', color:'#4bd30899', value:3},
    {status: 'Rejected', color:'#80808073', value:4},
    {status: 'Error on creation', color:'#ff000073', value:5},
    {status: 'Error on review', color:'#ff000073', value:6},
]
const StoryPublished = [
    // {status: 'All', value: null },
    {label: 'Yes', value: '1'},
    {label: 'No', value: '0'},
]


export default function StoriesFilters(props: any) {
    const { queryParams, loadStories } = props;
    const dispatch = useDispatch();
    const classes = useStyles();

    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        loadStories({
            ...queryParams,
            page: 1,
            [name]: value,
        });
    }
    return (
        <div className={classes.filtersWrap}>
            <FormControl sx={{ m: 1,  width: '15ch', marginLeft: 0 }}  size="small">
                <InputLabel id="demo-simple-select-helper-label">Published</InputLabel>
                <Select
                    id="demo-simple-select-helper"
                    defaultValue={queryParams?.isPublished || null}
                    label="Published"
                    variant="outlined"
                    name="isPublished"
                    size="small"
                    onChange={(event) =>handleFilterChange(event)}
                >
                    {StoryPublished.map((story)=>
                        <MenuItem key={`${story.value}${story.label}`} value={story.value}>{story.label}</MenuItem>
                    )}
                </Select>
            </FormControl>
            <FormControl sx={{ m: 1,  width: '15ch' }}  size="small">
                <InputLabel id="demo-simple-select-helper-label">Status</InputLabel>
                <Select
                    id="demo-simple-select-helper"
                    defaultValue={queryParams?.statusId || null}
                    label="Status"
                    variant="outlined"
                    name="statusId"
                    onChange={(event) =>handleFilterChange(event)}
                >
                    {StoryStatus.map((story)=>
                        <MenuItem key={`${story.value}${story.status}`} value={story.value}>{story.status}</MenuItem>
                    )}
                </Select>
            </FormControl>
            <FormControl sx={{ m: 1,  width: '10ch' }}  size="small">
                <div className={classes.editButton}
                     onClick={() => {
                         dispatch(Stories.clearFilters());
                         loadStories();
                     }}
                >Clear</div>
            </FormControl>
        </div>
    );
}