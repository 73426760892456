import * as React from 'react';
import {useEffect, useState} from "react";

import { useStyles } from './styles';
import {
    Box,
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem,
    Select,
    TextField
} from "@mui/material";
import StoryImage from "./StoryImage";
import StoryVideo from "./StoryVideo";
import {IOneStory} from "../../store/types";
import {Stories} from '../../store/slices'
import {useDispatch} from "react-redux";
import Skeleton from "@mui/material/Skeleton";



const StoryStatus = [
     {status: 'Created', color:'#00ccff54', value:1},
     {status: 'Pending review', color:'#ffa500ab', value:2},
     {status: 'Approved', color:'#4bd30899', value:3},
     {status: 'Rejected', color:'#80808073', value:4},
     {status: 'Error on creation', color:'#ff000073', value:5},
     {status: 'Error on review', color:'#ff000073', value:6},
]

const ProductVariants = [
    {title: '16\" Shaped Pillow', value: 1},
    {title: '24\" Shaped Pillow', value: 2},
    {title: '32\" Shaped Pillow', value: 3},
    {title: 'Keychain Pillow', value: 4},
    {title: '16\"x16\" Square Pillow', value: 5},
    {title: '18\"x18\" Square Pillow', value: 6},
    {title: 'Mini 10\"', value: 7},
    {title: '22\" Shaped Pillow', value: 8},
]

const StoryOccasion = [
    {title: 'Birthday Gift', value: 1},
    {title: 'Christmas Gift', value: 2},
    {title: 'Father’s Day Gift', value: 3},
    {title: 'Graduation Gift', value: 4},
    {title: 'Mother’s Day Gift', value: 5},
    {title: 'Wedding Gift', value: 6},
    {title: 'Back to school', value: 7},
    {title: 'Passing of pet', value: 8},
    {title: 'Just because', value: 9},
    {title: 'Valentines day', value: 10},
    {title: 'Anniversary', value: 11},
    {title: 'Pride Month', value: 13},
]

const StoryRecipient = [
    {title: 'Prefer not to answer', value: 1},
    {title: 'Son', value: 2},
    {title: 'Daughter', value: 3},
    {title: 'Parent', value: 4},
    {title: 'Friend', value: 5},
    {title: 'Co-Worker', value: 6},
    {title: 'Grandparent', value: 7},
    {title: 'Myself', value: 8},
    {title: 'Spouse', value: 9},
    {title: 'Boyfriend/Girlfriend', value: 10},
    {title: 'Child', value: 11},
]



export default function StoryForm(props: any) {
    const { data, status } = props.data;
    const [formData, setFormData] = useState<IOneStory>({})
    const [pending, setPending] = useState(false);
    const classes = useStyles();

    const dispatch = useDispatch()
    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setPending(true);
        dispatch<any>(Stories.updateStory({id: data.storyId, formData})).then(()=>{setFormData({}); setPending(false)})
    };
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    }
    const handleCheckBoxChange = (e) => {
        const { name, checked } = e.target;
        setFormData({
            ...formData,
            [name]: checked ? 1 : 0,
        });
    }
    return (
        <div className={classes.wrapper}>
            {pending && <div className={classes.giftCardLoader}> <Skeleton animation="wave" width={'100%'} height={'100%'} variant="rounded" /></div>}

            <div className={classes.header}><span>Story</span></div>
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                <div className={classes.inputWrap}>
                    <FormControl sx={{ m: 1,  width: '30ch' }}>
                        <InputLabel id="demo-simple-select-helper-label">Product</InputLabel>
                        <Select
                            value={formData?.productId || data.productId || 1}
                            label="Product"
                            variant="outlined"
                            name="productId"
                            onChange={(event) =>handleInputChange(event)}
                        >
                            {ProductVariants.map((product)=>
                                <MenuItem key={`${product.value}${product.title}`} value={product.value}>{product.title}</MenuItem>
                            )}
                        </Select>
                    </FormControl>
                    <FormControl sx={{ m: 1,  width: '30ch' }}>
                        <InputLabel id="demo-simple-select-helper-label">Status</InputLabel>
                        <Select
                            id="demo-simple-select-helper"
                            value={formData.statusId || data.statusId || 1}
                            label="Status"
                            variant="outlined"
                            name="statusId"
                            onChange={(event) =>handleInputChange(event)}
                        >
                            {StoryStatus.map((story)=>
                                <MenuItem key={`${story.value}${story.status}`} value={story.value}>{story.status}</MenuItem>
                            )}
                        </Select>
                    </FormControl>
                    <FormControlLabel sx={{ m: 1,  width: '30ch' }} name="isPublished" control={<Checkbox defaultChecked={data.isPublished === 1 ? true : false} onChange={handleCheckBoxChange} />} label="Show Page" />
                </div>
                <div className={classes.inputWrap}>
                    <StoryImage onChange={handleInputChange} data={{image: data.imageLink}}/>
                    <div className={classes.rightColumn}>
                        <div className={classes.columnInputs}>
                            <FormControl sx={{ m: 1,  width: '30ch' }}>
                                <InputLabel id="demo-simple-select-helper-label">Occasion</InputLabel>
                                <Select
                                    value={formData.occasionId || data.occasionId || 1}
                                    label="Occasion"
                                    variant="outlined"
                                    name="occasionId"
                                    onChange={(event) =>handleInputChange(event)}
                                >
                                    {StoryOccasion.map((occasion)=>
                                        <MenuItem key={`${occasion.value}${occasion.title}`} value={occasion.value}>{occasion.title}</MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                            <FormControl sx={{ m: 1,  width: '30ch' }}>
                                <InputLabel id="demo-simple-select-helper-label">Recipient</InputLabel>
                                <Select
                                    value={formData.recipientId || data.recipientId || 1}
                                    label="Occasion"
                                    variant="outlined"
                                    name="recipientId"
                                    onChange={(event) =>handleInputChange(event)}
                                >
                                    {StoryRecipient.map((recipient)=>
                                        <MenuItem key={`${recipient.value}${recipient.title}`} value={recipient.value}>{recipient.title}</MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                        </div>
                        <FormControl sx={{ m: 1,  width: 'calc(100% - 16px)' , marginTop: '24px'}}>
                            {/*<InputLabel id="demo-simple-select-helper-label">Reason</InputLabel>*/}
                            <TextField
                                id="outlined-multiline-static"
                                label="Reason"
                                name="reason"
                                multiline
                                onChange={(event) =>handleInputChange(event)}
                                rows={8}
                                value={formData.reason || data.reason || ''}
                            />
                        </FormControl>
                    </div>
                </div>
                <div className={classes.inputWrap} >
                    <StoryVideo onChange={handleInputChange} data={{video: data.videoLink}}/>
                    <div className={classes.rightColumn}>
                    <FormControl sx={{ m: 1, width: '62ch'  ,maxWidth: 'calc(100% - 16px)', margin: '8px' }}>
                        <TextField
                            id="outlined-multiline-static"
                            label="Recommendation"
                            name="recommendation"
                            multiline
                            onChange={(event) =>handleInputChange(event)}
                            rows={5}
                            value={formData.recommendation || data.recommendation || ''}
                        />
                    </FormControl>
                    <FormControl sx={{ m: 1, width: '62ch'  ,maxWidth: 'calc(100% - 16px)', margin: '8px' }}>
                        <TextField
                            id="outlined-multiline-static"
                            label="Impact"
                            name="impact"
                            multiline
                            onChange={(event) =>handleInputChange(event)}
                            rows={5}
                            value={formData.impact || data.impact || ''}
                        />
                    </FormControl>
                    </div>
                </div>
                {Object.keys(formData).length != 0 && <div className={classes.buttonWrap}><Button type={"submit"} variant="contained">Save changes</Button></div>}
            </Box>
        </div>
    );
}