import {getAuthenticatedUser} from "../../thunk";

export default {
    [getAuthenticatedUser.pending as any]: (state) => {
        state.status = 'loading';
        state.error = null;
    },
    [getAuthenticatedUser.fulfilled as any]: (state, action) => {
        state.status = 'resolved';
        state.data = action.payload;
    },
    [getAuthenticatedUser.rejected as any]: (state, action) => {
        state.status = 'rejected';
        state.error = action.payload;
    },
}
