import {createStory, getAllStories, getStoryById, getStoryGiftCards, updateStoryById} from '../../api';
import {createAsyncThunk} from "@reduxjs/toolkit";
import store from "../../store";
import {setNotifications} from "../slices/notifications";

export const getStories = createAsyncThunk(
    'stories/getStories',
    async (queryParams, {rejectWithValue}) => {
        try {
            const stories = await getAllStories(queryParams);
            return stories;
        } catch (error: any) {
            store.dispatch(setNotifications({text: error.message, type: 'error'}))
            return rejectWithValue(error.message)
        }
    }
)

export const getStory = createAsyncThunk(
    'stories/getStory',
    async (id: string, {rejectWithValue}) => {
        try {
            const story = await getStoryById(id);
            return story;
        } catch (error: any) {
            store.dispatch(setNotifications({text: error.message, type: 'error'}))
            return rejectWithValue(error.message)
        }
    }
)

export const updateStory = createAsyncThunk(
    'stories/updateStory',
    async (data:{id: number, formData:object}, {rejectWithValue}) => {
        try {
            const story = await updateStoryById(data.id, data.formData);
            store.dispatch(setNotifications({text: 'Story updated success', type: 'success'}));
            return story;
        } catch (error: any) {
            store.dispatch(setNotifications({text: error.message, type: 'error'}))
            return rejectWithValue(error.message)
        }
    }
)
export const CreateStory = createAsyncThunk(
    'stories/createStory',
    async (data:{formData:object, onSuccess:any}, {rejectWithValue}) => {
        try {
            const story = await createStory(data.formData);
            store.dispatch(setNotifications({text: 'Story created success', type: 'success'}));
            if (data.onSuccess) {
                data.onSuccess(story);
            }
            return story;
        } catch (error: any) {
            store.dispatch(setNotifications({text: error.message, type: 'error'}))
            return rejectWithValue(error.message)
        }
    }
)

export const getStoryGiftCarts = createAsyncThunk(
    'stories/getStoryGiftCarts',
    async (data: any, {rejectWithValue}) => {
        try {
            const story = await getStoryGiftCards(data);
            return story;
        } catch (error: any) {
            store.dispatch(setNotifications({text: error.message, type: 'error'}))
            return rejectWithValue(error.message)
        }
    }
)

