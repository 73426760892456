import {getAuthInfo, logOut} from '../../api';
import {createAsyncThunk} from "@reduxjs/toolkit";

export const getAuthenticatedUser = createAsyncThunk(
    'auth/getAuthenticatedUser',
     (_, {rejectWithValue}) => {
        try {
            const AuthenticatedUser = getAuthInfo();
            return AuthenticatedUser;
        } catch (error: any) {
            return rejectWithValue(error.message)
        }
    }
)
export const logOutUser = createAsyncThunk(
    'auth/logOutUser',
    async (_, {rejectWithValue}) => {
        try {
            const AuthenticatedUser = await logOut();
            return AuthenticatedUser;
        } catch (error: any) {
            return rejectWithValue(error.message)
        }
    }
)


