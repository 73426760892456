import {createSlice} from '@reduxjs/toolkit';
import store from '../../../store';

const NotificationsSlice = createSlice({
    name: "notifications",
    initialState: {
        data: <any>[],
        status: null,
        error: null,
        queryParams: null,
    },
    reducers: {
        setNotifications: (state, action) => {
            const id = new Date().toISOString();
            state.data.push({...action.payload, id});
            setTimeout(() => {
                store.dispatch(removeNotification(id));
            }, 10000);
        },
        removeNotification: (state, action) => {
            state.data = [...state.data].filter((notification: any) => notification.id !== action.payload)
        },
    },
});
const {reducer} = NotificationsSlice
export const { setNotifications, removeNotification } = NotificationsSlice.actions;
export {reducer};
